// articles.js

const articles = [
    {
        id: 1,
        image: "assets/images/blog/blog_01.jpg",
        imageBg: "/assets/images/blog/blog-1.jpg",
        title: "الاحتجاج على جنازة أيمن صفيّة: إعادة هندسة طقوس الموت في فلسطين",
        description: "تُعد جنازة أيمن صفيّة، الراقص الجريء، علامة فارقة في المجتمع الفلسطيني، حيث أبت جنازته الاستسلام للأعراف الاجتماعية.",
        titleEn: "Protests at Ayman Safi’s Funeral",
        descriptionEn: "Ayman Safi's funeral, the bold dancer, stands out in Palestinian society as his funeral defied social norms.",
        date: "20 - 06 - 2020 ",
        author: "Maram Masarwi ",
        isArabic: 1,
        content: `
            <div class="single-blog__para font-ar">
            
                <p>
                    ثمّة مَنْ يصبحون بموتهم أكثر قوّة وعنادًا؛ مقولة تصحّ حتمًا في حالة الفنّان الراحل أيمن صفيّة، الراقص الجريء؛ إذ كان علامةً فارقة في حياته حين لم يتردّد في التعبير عن هويّته الجندريّة علنًا، وحين امتهن الرقص واختاره طريقة حياة، وأصبح أيقونة في موته، عندما تحوّلت جنازته إلى مشهد هزّ الحيّزَيْن العامّ والخاصّ الاجتماعيّين؛ فشخصيّة أيمن غير التقليديّة أبت بموتها الاستسلام للأعراف الاجتماعيّة العامّة، ونجحت في وضع ثقافة الموت وطقوسه على طاولة النقاش الاجتماعيّ.
                </p>
                   
                <p>
                   <h3 class="single-blog__title font-ar" >الجنازة والرأي العامّ</h3>
                </p>
                <p>
                    يمكن ادّعاء أنّ ما أدّت إليه جنازة أيمن، هو تقسيم الجماهير الغفيرة الّتي شاركت في جنازته، والرأي العامّ الاجتماعيّ في فلسطين التاريخيّة، إلى ثلاث مجموعات رئيسيّة: الأولى المتطرّفون دينيًّا الّذين يتّهمون أيمن وأصدقاءه بالشذوذ الجنسيّ والأخلاقيّ والإلحاد، ويقطعون بأنّهم من أهل النار الّذين لا يدخلون الجنّة، ويجب الحدّ من سلوكهم المتحدّي للمجتمع والأعراف.
                </p>
                <p>
                    أما الثانية فهم المحافظون، الّذين عبّروا عن استنكارهم لمشاركة النساء والرجال في الرقص والغناء والتصفيق، ومحاولة دخول المقبرة؛ بحجّة الاعتداء على حرمة القبور والأموات.
                </p>
                <p>
                    أما الثالثة فهم المدافعون عن جنازة أيمن كما نُظِّمَت، وهم أصوات متعدّدة اجتمعت على أن تعبّر الجنازة عن شخص الراحل، وأن يكرّمه أصدقاؤه بالطريقة الّتي كان يرغب فيها، وتناسب معتقداته وآراءه وطريقته في الحياة، بالأشكال التعبيريّة الّتي اختاروها.
                </p>
                <blockquote class="single-blog__para__quote">
                  <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
                      "تاريخنا الفلسطينيّ والعربيّ مليء بأدب الرثاء والخطب والأهازيج والنواح على الموتى، ومن ذلك الرقص الجنائزيّ، ويكون هذا الرقصة في حلقة، وتُسمّى حركاته بـ "الحجل على الميّت"، تشبيهًا بمشية طائر الحجل..."
                    </p>
                  </div>
                </blockquote>
                <p>
                   <h3 class="  single-blog__title font-ar">طقوس الحزن</h3>
                </p>
                <p>
                    تاريخنا الفلسطينيّ والعربيّ مليء بأدب الرثاء والخطب والأهازيج والنواح على الموتى، ومن ذلك الرقص الجنائزيّ، ويكون هذا الرقص في حلقة، وتُسمّى حركاته بـ \u0022الحجل على الميّت\u0022، تشبيهًا بمشية طائر الحجل، وهي ظاهرة انتشرت في مناطق عديدة في فلسطين، من الجنوب إلى أقصى الشمال، وبين المسلمين والمسيحيّين.
                </p>
                <p>
                    كانت هذه الطقوس ضمن الأمور المقبولة في المجتمع الفلسطينيّ، في التعامل مع الفقدان ومعالجة مشاعره، حتّى بدء تصاعد التيّار الدينيّ المعاصر، وتحديدًا المرتبط بالسلفيّة والإخوان المسلمين، فراح يحلّل ويحرّم كأنّنا لم نحترم موتانا من قبل، وكأنّ انتشار التديّن بهذا النمط جعل منّا مجتمعات أكثر أخلاقًا وقِيَمًا، وكأنّ ثقافة الموت الّتي راحت تعمّمها السلفيّة لم تخلّ بحرمة الموت والموتى، عندما أصبح الموت مشهدًا شبه ثابت في الشارع العربيّ.
                </p>
                <p>
                    هذه الحال جعلت الحديث عن الموت وطقوسه، والاستعداد لمناقشته في مجتمعنا، ليس بالأمر الشائع، وبالتأكيد ليس بالهيّن، ولا سيّما عندما يتعلّق الأمر بحقّ الميّت أو ذويه في الاعتراض على طقوس الجنازة والتأبين، بصفتها من المسلّمات والتابوهات الاجتماعيّة؛ فهذا الشأن يقتصر على المؤسّسة الدينيّة، وبالأحرى الذكور، في العقود الثلاثة الأخيرة.
                </p>
                <p>
                   <h3 class="  single-blog__title font-ar">إعادة صياغة ثقافة الموت</h3>
                </p>
                <p>
                    وفي السياق الّذي أصبح به التراث الرمزيّ الدينيّ على وجه التحديد، في وضعيّة صدام مع أشكال الحياة اليوميّة الّتي نعيشها، إلّا أنّ الموت وطقوسه، حظي بسيطرة تامّة من قِبَل رجالات الدين. وقد أسهم التيّار الدينيّ الشعبيّ، ومعه المؤسّسة الدينيّة، والجهاز التعليميّ الدينيّ، في صياغة ثقافة الموت والحياة والدنيا والآخرة، من خلال بثّ أفكار الترهيب والوعيد، من علامات الآخرة، وعذابات القبر، والطريق إلى جهنّم. ولأنّ علاقة معظم المجتمع الفلسطينيّ بالدين علاقة عامّة وشعبيّة، وقائمة على استخدام الرموز الدينيّة الّتي تُعَدّ المصدر الرئيسيّ المنظّم للخطاب الدينيّ، وغالبًا ما تخضع لسياسات المعرفة الّتي تؤطّر وتؤوّل وفق مصالحها؛ فإنّ هذا المضمون الحامل للتفكير العفويّ والشعبيّ وأمن المعرفة، جعل مساءلة الموت وطقوسه مسألة مركّبة وحسّاسة، ونصّب رجالات الدين حرّاسًا لبوّابة الآخرة، من خلال تمثيلهم دور المخَلّصين من عبء الذنوب، بواسطة الذكر الإلهيّ الّذي يلقّنونه للموتى والأحياء على السواء، حين يطلبون لهم العفو والمغفرة الإلهيّة، وكأنّنا بحاجة إلى وسطاء لطلب الرحمة والمغفرة.
                </p>
                <p>
تنتهز مجموعات دينيّة مختلفة - بصورة مُمَنْهَجَة – الموت، كونه لحظة انكسار وأزمة نفسيّة لدى أهل الفقيد، لبثّ أفكارهم وقيمهم، باستخدام الواعظين والواعظات، الّذين غالبًا ما يقدّمون خطابهم السلفيّ، وهي ظاهرة انتشرت في فلسطين في العقدين الأخيرين، وغالبًا يكون بيت القصيد لدى مجموعات النساء، ويتلخّص بحفظ العفّة وضرورة صونها، لتبقى ذكرى الفقيد الطيّبة؛ بالتزام "الحجاب"، والتأكيد على أنّ الحياة زائلة، وأنّ علينا الاستعداد لبيت الآخرة. أصبح الموت مُسْتَخْدَمًا من قِبَل ممثّلي الدين وممثّلاته، منصّةً للترهيب ولفرض نظام السيطرة على جسد المرأة.                    
                </p>
                <p>
                    وقد منح رجال الدين أنفسهم رخصة مؤكّدة الضمان، عندما استولوا على مراسيم الدفن؛ فالحياة والموت وما يرافقانهما من ثقافة الآخرة ويوم الحساب، أمور مركزيّة في مجتمعنا، وإذ إنّنا مجتمع يعيش حالة من الأزمات القيميّة والاجتماعيّة، ونهاب الحديث والخوض في ثقافة الموت؛ أصبح رجال الدين ذوو التوجّهات السلفيّة والإخوانيّة يُشَرِّعون ويُحَرِّمون ويرسمون الحدود المقبولة وغير المقبولة، وأصبحوا يُفتون بالمسموح والممنوع في مراسم الموت وطقوسه، والأهمّ أنّ هذه التوجّهات أصبحت تُهَنْدِس الحيّز العامّ الاجتماعيّ في حالات الموت؛ فهي تقرّر مَنْ يحقّ له المشاركة في الجنازة ومَنْ لا يحقّ له، ومَنْ يصلّي على الميّت ومَنْ لا يصلّي، ومَنْ يدخل المقبرة ومتى، ثمّ غالبًا ما تطول هذه التحريمات النساء.
                </p>
                 <blockquote class="single-blog__para__quote">
                  <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
                      "تنتهز مجموعات دينيّة مختلفة - بصورة مُمَنْهَجَة – الموت، كونه لحظة انكسار وأزمة نفسيّة لدى أهل الفقيد، لبثّ أفكارهم وقيمهم، باستخدام الواعظين والواعظات، الّذين غالبًا ما يقدّمون خطابهم السلفيّ، وهي ظاهرة انتشرت في فلسطين في العقدين الأخيرين... 
"
                    </p>
                  </div>
                </blockquote>
                <p>
                كلّ شيء في المجتمع الفلسطينيّ أصبح موضوع اختلاف اليوم، من السياسة، والهويّة، والجندر، والانتماء، والأسرة، وقوانين الأحوال الشخصيّة... إلخ. حتّى الموت لم يعد استثناءً في ذلك، بعد أن كان منطقةً لا تُناقش إلّا في حالات نادرة، وغالبًا يكون ذلك في إطار قصّة تراجيديّة، فتعلو بعض الأصوات ثمّ تُسْكَت سريعًا بذريعة العيب والحرام، أو التوقيت غير المناسب، أو حساسيّة الموقف، أو عدم الرغبة في افتعال المشاكل، وفق ما يقرّره الوكلاء. وحيث إنّ المجتمع الفلسطينيّ واقع تحت تأثير تيّارين مركزيّين: الأوّل يتبنّى قيم الحداثة والتغيير، والآخر تقليديّ وثابت، وكلا التيّارين يؤثّران فيه معًا، إلّا أنّ الإمكانيّات المقترحة والمفروضة في المجتمع الفلسطينيّ، في ما يتعلّق بثقافة الموت، تقليديّة ومتشدّدة عمومًا، وهي تتناقض ومعايير التحديث، كما أنّها في الأعمّ تجعل طقوس الموت أشبه بإسقاط واجب فارغ من المعنى معظم الأحيان، يثقل على كاهل المعزِّي والمُعَزَّى.
                </p>
                 <p>
                   <h3 class="  single-blog__title font-ar">هندسة حيّز الموت الاجتماعيّ</h3>
                </p>
                <p>
                يجب الإقرار بأنّ جنازة أيمن صفيّة وضعت موضوعًا شائكًا كالحقّ في اختيار طبيعة الجنازة وطقوسها على طاولة النقاش في المجتمع الفلسطينيّ، والأهمّ أنّ الجنازة اخترقت حيّز الموت العامّ، الّذي فرضه رجالات الدين تحت مسمّيات القيم والأعراف الدينيّة، وقدّمت نموذجًا بديلًا على الأقلّ في الوعي الاجتماعيّ لإمكانيّات أخرى؛ جنازة فيها الرقص والغناء والشعر والبكاء، يختلط فيها الرجال والنساء دون أن يصابوا بلوثة جنسيّة، جنازة استطاعت تجميع أكثر من معتقد سماويّ وغير سماويّ تحت مظلّة حزن واحدة؛ فالصلاة كانت إسلاميّة والعزاء كان في الكنيسة، وهي من أعظم الرسائل الفلسطينيّة وأجملها، وهي بالتأكيد ليست رسالة الدين السلفيّ والإخوانيّ؛ فصورة رجال الدين من مختلف الطوائف، أبناء البلد الواحد والحيّ الواحد، يصلّون جنبًا إلى جنب، أزعجت العديد من الأشخاص، لأنّها صورة نجحت في كسر النمطيّة والتابوهات. الجنازة أخلصت لأيمن وخياراته، وهذا ما أثار حفيظة مَنْ اعترض؛ فالاعتراض لم يكن على حرمة الأموات، فالموت واحد، ولم يَعُد أيّ من الأموات ليخبرنا عن حرمة الموت، إنّما كان الاعتراض على اختراق نظام الأحياء، نظام صارم جرت هندسته بهدف السيطرة على الحياة والموت.
                </p>
                <p>
                هذا الاختراق أظهر أنّه من اللازم إعادة المساءلة في طقوس الموت والدفن، وعلاقتها بمختلف أنظمة المعاني الّتي تحكم السلوكيّات الاعتياديّة للمجتمع الفلسطينيّ، ومدى الانسجام الّذي يربط بين السلوك الاجتماعيّ، والمباني المعياريّة المعرفيّة الّتي نحن بحاجة إلى إعادة مساءلتها؛ نظرًا إلى ظهور أبعاد جديدة للتجارب الفرديّة والجماعيّة.
                </p>
                 <blockquote class="single-blog__para__quote">
                 <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
"من اللازم إعادة المساءلة في طقوس الموت والدفن، وعلاقتها بمختلف أنظمة المعاني الّتي تحكم السلوكيّات الاعتياديّة للمجتمع الفلسطينيّ، ومدى الانسجام الّذي يربط بين السلوك الاجتماعيّ... "
                    </p>
                  </div>
                </blockquote>
                <p>
                علينا إعادة فتح ملفّ "ثقافة الموت" ومساءلتها من جديد؛ فلكلّ فرد الحقّ في أن يختار الطريقة الّتي يريد من خلالها الانتقال إلى العالم الآخر، لكلّ فرد الحقّ في أن يختار مَنْ يريد ليمشي في جنازته، ومَنْ يريد أن يحمل نعشه، وإذا كان يريد أن يصلّوا على جثمانه أو لا، ومَنْ يصلّي عليه وكيف، وإن كان يفضّل البكاء أو الغناء عليه، وإن كان يريد أن يُكْتَب على قبره نصوص دينيّة أو شعر مثلًا، وإن كان يريد قبرًا متواضعًا أو حفرة بلا ذكر أو اسم، أو حرقًا لجثّته أو التبرّع بها لأغراض علميّة؛ فمَنْ يستطيع أن يختار في حياته يحقّ له الاختيار في مماته.
                </p>
            </div>
        `
    },
    {
        id: 2,
        image: "assets/images/blog/blog_01.jpg",
        imageBg: "/assets/images/blog/blog-2.webp",
        title: "عَ السكين يا بطيخ…",
        description: "عَ السكين يا بطيخ… الهوس الإسرائيلي برمزية البطيخ الفلسطينيّعَ السكين يا بطيخ… الهوس الإسرائيلي برمزية البطيخ الفلسطينيّ",
        titleEn: " al-sikin ya batikh...",
        descriptionEn: "al-sikin ya batikh....... The Israeli Obsession with the Symbolism of Palestinian Watermelon",
        date: "18 - 06 - 2023 ",
        author: "Maram Masarwi ",
        isArabic: 1,
        content: `
            <div class="single-blog__para font-ar">
            
                <p>
في العام 1984، نشرت صحيفة "حدشوت" الإسرائيلية الخبر التالي: "أصدرت المحكمة المركزية في نتانيا حكماً على محمد تاية، الملقّب محمد بطيخ، من قرية قلنسوة في المثلث، بالسجن لمدة خمس سنوات، بتهمة بيعه بطيخاً يحمل ألوان منظمة التحرير الفلسطينية في الكشك الذي أقامه في مفرق بيت ليد. تم القبض على محمد من قبل الوحدة الخاصة لمكافحة الإرهاب، بتهمة بيعه البطيخ والذي يحمل ألوان العلم الفلسطيني، أي الأخضر، الأحمر، الأبيض والأسود، وذلك وفق قانون منع الإرهاب. وقد عرض المدعي العام اللافتة التي قام محمد بتعليقها على كشكه، حيث حملت اللافتة صورة لسكين وبطيخة، وكتب عليها: عالسكين يا بطيخ. وطلب المدعي العام بأن تعاقب المحكمة المتهم أشد العقاب، وذلك لدعمه للإرهاب وبسبب رغبته بالقضاء على اليهود، وفق تعبيره، وطعنهم بالظهر. وافقت المحكمة على طلب المدعي العام وحكمت بالسجن لخمسة سنوات على المتهم وغرامه قيمتها 50.000 شيكل".
                </p>
                   <p>
                   قد يبدو الخبر للوهلة الأولى كدعابة أو نكتة بعيدة عن الواقع، لكن، وعلى ما يبدو، فإن حياة الفلسطيني تتأرجح بين التراجيديا والكوميديا، بل إن الكوميديا أصبحت أحياناً أداة تفاعل وتأقلم للحظات الحرجة في التاريخ الفلسطيني، فمن خلال استخدام الفكاهة يعبر الإنسان الفلسطيني عن تفوّقه وعن الانتصار على الخصم، مقارنة بساحة المعركة.

</p>
 <blockquote class="single-blog__para__quote">
                  <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
                    طرح الفنان عصام بدر سؤالاً: "ماذا لو رسمت وردة بألوان العلم الفلسطيني، هل يعتبر ذلك مخالفا لتعليماتكم؟"، فأجابه الضابط الإسرائيلي: "ممنوع طبعاً، ممنوع رسم أي شيء يحمل ألوان العلم ولو حتى بطيخة"
                    </p>
                  </div>
                </blockquote>
                <p>
                تنبع الدعابة إذن من رغبة الفلسطيني في الشعور بقوّته، وربما بخصاله الحميدة مقارنة بالمحتل، وهذا ما حدث بعد نكسة حزيران عام 1967، حيث حظرت الحكومة الإسرائيلية جميع العروض العامة للعلم الفلسطيني وألوانه، فأي عرض علني للعلم، من المنشورات إلى الإعلانات وحتى الصور الفوتوغرافية القديمة، كان يودي إلى السجن أو إلى  ما هو أسوأ.
                </p>
                <p>
                   <h3 class="single-blog__title font-ar" >ماذا لو رسمت وردة؟
</h3>
                </p>
                <p>
في نهاية السبعينيات، أغلقت القوات الصهيونية معرضاً فنياً رئيسياً في رام الله، واستدعت ثلاثة فنانين: نبيل عناني، سليمان منصور وعصام بدر، لإدراجهم ألوان العلم الفلسطيني في أعمالهم الفنية. قام قائد الشرطة الإسرائيلية حينها بتهديدهم تارة ومحاولة رشوتهم تارة أخرى لنزع الطابع السياسي عن فنّهم. كان يحاول إقناعهم بعدم القيام بأي فن سياسي، فطرح حينها الفنان عصام بدر سؤالاً: "ماذا لو رسمت وردة بألوان العلم، هل يعتبر ذلك مخالفا لتعليماتكم؟"، فأجابه الضابط: "ممنوع طبعاً، ممنوع رسم أي شيء يحمل ألوان العلم ولو حتى بطيخة". 

                </p>
                <p>
لا بد أن اجتماع الألوان الأربعة للعلم الفلسطيني في عمل من ألذّ وأطيب أعمال الطبيعة الصيفية شكّل حالة من الهوس المرضي لكل دلالة على الوجود الفلسطيني، حتى لو كان ذلك بطيخة، فالاحتلال قام بتسمية الفلسطيني بالعربي تارة والإرهابي تارة أخرى، وغيّب بل منع كل ما هو فلسطيني بهويته، إيماناً وقناعة بأن الوقت كفيل بمحوها. إلا أن وجود هذه الفاكهة أصبح وجوداً مؤرقاً للإسرائيلي، وخصوصاً أنها فاكهة الصيف المحببة والأكثر شعبيه وانتشاراً لدى الفلسطينيين، على مدار المائة عام الأخيرة على الأقل. ففلسطين كانت مركزاً مهماً، بسبب جغرافيتها وتربتها، لزراعة وتصدير البطيخ.
                </p>
                ولا يزال المزارعون يعلقون على الحجم المذهل للبطيخ الفلسطيني وشعبيته كصادرات إلى لبنان والأردن وسوريا ومصر ودول أخرى، وكان يقام كل عام بقرية الحرم – سيدنا علي المهجرة عام 1948، مهرجان البطيخ السنوي، الذي كان يجمع مئات المزارعين من جميع أنحاء فلسطين لعرض منتوجهم من البطيخ. استمرّ هذا المهرجان حتى نكبة عام 1948، ومعها جلبت سلطات المستوطنين شركات البذور الخاصة بها وأغرقت السوق، وأخرجت الفلسطينيين من المنافسة، واحتكر الإسرائيليون زراعة البطيخ.


                <p>
                إذن، أصبح لهذه الفاكهة تاريخ سياسي لدى الفلسطيني والإسرائيلي على السواء، فلدى الإسرائيلي شكلت هذه الفاكهة حالة هوس، وأصبحت رمزاً للعلم الفلسطيني بل للوجود الفلسطيني، أما لدى الفلسطينيين فيُعتبر البطيخ رمزاً للمقاومة ونوعاً من أنواع الحراك الفني- السياسي، بألوانه التي تعكس العلم الفلسطيني، ويمكن رؤية صور البطيخ على الجدران الحجرية في صالات العرض في رام وبيت لحم. فعلى مدى عدة عقود، أصبح البطيخ الفلسطيني تعبيراً عاماً عن الفخر الثقافي في الأعمال الفنية التي تمثل النضال ضد الفصل العنصري الإسرائيلي. في العقود التي تلت ذلك، استعاد الفلسطينيون البطيخ كفن احتجاجي ضد الاحتلال الإسرائيلي. 
                </p>
                  <p>
                   <h3 class="  single-blog__title font-ar">علم جديد لفلسطين</h3>
                </p>
                <p>
                وفي أعقاب الانتفاضة الثانية، ابتكر الفنان الفلسطيني خالد حوراني سلسلة تحت عنوان "علم جديد لفلسطين"، والتي ظهرت في أطلس فلسطين الذاتي عام 2007. وقدم فيها البطيخة كعلم هذه المرة وليس مجرّد ترميز له، اعتماداً على القصة التي سمعها من زملائه الفنانين وعلى تاريخ قصة البطيخ مع الاحتلال. وقد أصبح هذا العمل الساخر يحظى باهتمام شديد نظراً لقوته الاحتجاجية والتعبيرية ولبساطته الفنية أيضاً. 
                </p>
                <p>
                تبدو شريحة البطيخ منتصبة ومقطوعة بشكل كبير، وتنقل طبيعة ساخرة، على خلفية بيضاء. قام حوراني بدمج خط رفيع من البذور السوداء والظلال على طول القشرة لجعلها تبدو ثلاثية الأبعاد. وقام لاحقاً بعزل إحدى الشاشات الحريرية وأطلق عليها عنوان "ألوان العلم الفلسطيني" (2013).
                </p>
                <p>
                حوراني هو المدير السابق لدائرة الفنون الجميلة في وزارة الثقافة الفلسطينية، وشخصية محورية في تنشيط الخطاب الفني في فلسطين، والمؤسس والمدير للأكاديمية الدولية للفنون في فلسطين. رسم نسخة كبيرة من البطيخ في معرض في تولوز، فرنسا، وظهرت أشكال مختلفة في دارة الفنون في عمان، الأردن، ومركز الفن المعاصر، غلاسكو. يقول: " إن فكرة العمل ليست تخليداً لخيال الحاكم العسكري المرضي، بل تخليداً لمنعه"، ويضيف: "العلاقة بين الفن والسياسة بفلسطين علاقة وثيقة، لدرجة أن الفن قد يكون مسيّساً أكثر من السياسة، فللفن في فلسطين تاريخ وباع طويل في التعبير ومحاكاة واقع الفلسطيني، وعمل البطيخة مثال حي على ذلك. حيث تعاملت الأوساط الفنية ووسائل الأعلام وشبكات التواصل الاجتماعي على زيادة الاهتمام بهذا الرمز وطريقة للتحايل على المنع، ولم يتم استخدامها و تجنيدها من قبل الناس في فلسطين فقط، وإنما من قبل الناس والمتضامنين في كل مكان".
                </p>
                   <blockquote class="single-blog__para__quote">
                  <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
"أصبح للبطيخ تاريخ سياسي لدى الفلسطيني والإسرائيلي على السواء، فلدى الإسرائيلي شكلت هذه الفاكهة حالة هوس، وأصبحت رمزاً للعَلَم الفلسطيني بل للوجود الفلسطيني، أما لدى الفلسطينيين فيُعتبر البطيخ رمزاً للمقاومة ونوعاً من أنواع الحراك الفني- السياسي"
                    </p>
                  </div>
                </blockquote> 
                <p>
                
                <p>
                   <h3 class="  single-blog__title font-ar">بطيخ حوراني ورينيه ماغريت</h3>
                </p>
                <p>
                انعكس ذلك بوضوح في حملات التضامن الضخمة وغير المسبوقة مع فلسطين ونضالها العادل. في حملة "أنقذوا الشيخ جراح" الأخيرة، أدى تجديد التقدير لعمل حوراني إلى ظهور البطيخ الفلسطيني الآن كعلم في تصاميم الجرافيك واللوحات الجدارية والرسومات والقمصان واللافتات. فيُظهر تصميم بسيط للفنان اليافاوي سامي بخاري، شريحة على شكل خريطة فلسطين. وصممت حنان السقوف كوفية باللونين الأحمر والأخضر، مع أنماط شبكية وأوراق زيتون تمثل سبل العيش والمرونة، على التوالي. في روتردام، علّق طلاب مدرسة الفنون لافتة جمعت بين بطيخ حوراني ورينيه ماغريت، بعد أن أسقطت الشرطة لافتة أكثر وضوحاً احتجاجاً على التطهير العرقي الإسرائيلي. كما أخذت بطيخة حوراني شكلاً جديداً، حتى تحولت لأيقونة فنية - سياسية في مواجهة مستمرة مع الاستعمار الاستيطاني والفصل العنصري، كأحداث الشيخ جراح بالقدس، فأصبح حمل شريحة من البطيخ الطازج، أو رفع علم يحتوي على شريحة من البطيخ في الخارج، عملاً احتجاجياً.
                </p>
                <p>
                وفي ظل الرقابة التي تفرضها شبكات التواصل الاجتماعي، أخذت البطيخة أبعاداً أوسع، وأصبحت تقليداً جديداً على الإنترنت، يوحّد الفلسطينيين في جميع أنحاء العالم، ويستمد التأثير من أولئك الذين حاربوا من أجل حرية التعبير طوال أواخر القرن العشرين.
                </p>
                <p>
                يعقب حوراني على هذه التحولات: "بالنسبة لي، كان الأمر مفاجئاً نوعاً ما. حيث كانت البطيخة مجرّد مشروع من أحد مشاريعي، والذي لم يكن واسع الانتشار كما هو الآن"، ويضيف: "إنه نوع فريد من أنواع التضامن والتفاعل الإنساني والسياسي، وهو تفاعل قوي الوقع للغاية. حينها بصراحة، لم أعرف كيف أتعامل معه. مع هذا الاهتمام الكبير المفاجئ الذي رافق هبّة الشيخ جراح الأخيرة، بعض الناس يحصلون عليه كوشم أو دبابيس، والبعض الآخر يصمّمه ضمن أزيائه، وآخرون على الأعلام، ووسائط مختلفة. أشعر بالسعادة في أن أكون طرفاً في هذه القصة، ولأن عملي الفني من باب السخرية قام بدوره ولو بعد حين، ألا وهو لفت الانتباه إلى القضية الفلسطينية وتوسيع التضامن مع الشعب الفلسطيني".
                </p>
               
                
                 <blockquote class="single-blog__para__quote">
                 <span class="single-blog__para__quote__icon">
                    <i class="icofont-quote-right"></i>
                  </span>
                  <div class="single-blog__para__quote__text">
                    <p>
                    "في ظل الرقابة التي تفرضها شبكات التواصل الاجتماعي، أخذت البطيخة أبعاداً أوسع، وأصبحت تقليداً يوحّد الفلسطينيين في جميع أنحاء العالم، ويستمد التأثير من أولئك الذين حاربوا من أجل حرية التعبير"
                    </p>
                  </div>
                </blockquote>
                <p>
                قبل أسابيع قليلة، وبالتحديد ب 18 أيار/ مايو 2023، أقرّ البرلمان الإسرائيلي بقراءة تمهيدية لمشروع قانون يحظر رفع العلم الفلسطيني بالتجمعات لثلاثة أفراد أو أكثر، وصوّت لصالح المشروع 54 نائباً مقابل 16 معارضاً. إذا تمت الموافقة على المشروع في ثلاث قراءات، فسيكون من الممكن فرض عقوبة تصل إلى السجن لمدة عام على أولئك الذين يلوّحون بالعلم الفلسطيني. ويمكن هنا السؤال: هل ستكون بطيخة الحوراني ضمن المحظورات بهذا القانون؟ فذهنية الحاكم العسكري الفاشية والذي حظر رسم البطيخة هي ذات الذهنية التي تسيّر القيادة الإسرائيلية الحالية، فالطغاة لا يخافون من الذكريات فقط، بل من كل ما قد يثير ذاكرتهم حتى ولو كان بطيخة.


                </p>
            </div>
        `
    }
];

export default articles;
